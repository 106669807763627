
<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-row class="ma-2">
            <v-col cols="12" sm="3" md="3">
                <v-select v-model="selected_org" label="Organization" :items="org_list" item-text="name" item-value="id"
                    outlined></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
                <v-btn class="primary mt-2" @click="fetchCurrentDashboardData"> Fetch </v-btn>
            </v-col>
        </v-row>
        <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
        <div v-else>

            <div class="ma-3" v-if="Array.isArray(overall_ay_wise_count_list) &&
                overall_ay_wise_count_list.length">

                <fieldset style="border-color: white;" class="pa-3">
                    <legend>Academic Year-Wise Company</legend>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <center>
                                <barchart :data="program_bar_data" :key="rerender1" :options="chartOptions" />
                                <linechart :data="program_bar_data" :key="rerender1" :options="chartOptions" />
                            </center>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-simple-table id="exceltable">
                                <tbody>
                                    <tr>
                                        <th>Academic Year</th>
                                        <th>Company Count</th>

                                    </tr>
                                    <tr v-for="(item, index1) in overall_ay_wise_count_list" :key="index1">

                                        <td> {{ item[0].ay }} </td>

                                        <td> {{ item[1] }} </td>

                                    </tr>

                                </tbody>
                            </v-simple-table>
                        </v-col>

                    </v-row>

                </fieldset>

            </div>



        </div>

    </div>
</template>
<script>
import linechart from '../TPO/chart/line_chart.vue'
import barchart from '../TPO/chart/bar_chart.vue'
import axios from "axios";
export default {
    components: { barchart, linechart },
    data: () => ({
        selected_ay: "",
        loading: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        selected_org: "",
        rerender: 0,
        overall_ay_wise_count_list: [],

        chartOptions: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },
                }]
            },
            responsive: true,
        },
    }),
    props: ['ay_list', 'current_ay', 'org_list', 'current_org'],
    mounted() {

        this.selected_ay = this.current_ay;
        this.selected_org = this.current_org.id;

    },
    methods: {
        fetchCurrentDashboardData() {
            this.loading = true;
            var params = {
                selected_ay: this.selected_ay,
                selected_org: this.selected_org
            };

            axios.post("/Dashboard/get_overall_company_dashbord_details", params).then((res) => {
                if (res.data.msg == "200") {
                    this.rerender++;


                    this.loading = false;
                    this.flag = true;
                    this.overall_ay_wise_count_list = res.data.overall_ay_wise_count_list;
                    if ((this.overall_ay_wise_count_list != 'undefined' || this.overall_ay_wise_count_list.length > 0)) {
                        var label = [];
                        var company_data = [];
                        var total = 0
                        for (var x in this.overall_ay_wise_count_list) {
                            label.push(this.overall_ay_wise_count_list[x][0].ay);
                            company_data.push(this.overall_ay_wise_count_list[x][1]);

                            total += this.overall_ay_wise_count_list[x][1];
                            this.program_bar_data = { "labels": label, "datasets": [{ "data": company_data, "label": "Academic Year Wise Company", "backgroundColor": 'skyblue', }] };

                        }
                        var map = { "ay": "TOTAL", "id": "TOTAL" };
                        var array = []
                        array.push(map);
                        array.push(total);
                        this.overall_ay_wise_count_list.push(array);

                    }

                    this.loading = false;
                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.loading = false;
                }
            });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};
</script>
<style scoped>
legend {
    background-color: gray;
    color: white;
    padding: 5px 10px;
}
</style>
